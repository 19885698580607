<template>
  <travio-center-container grid-width='full'>
    <h2 class="mb-4" style="color:#636363">Gift Vouchers</h2>

    <vx-card>
      <div class="vx-row">
          <div class="vx-col w-full">
            <vs-button @click="onAdd" class="mr-4 sm:mb-0 mb-2">Add</vs-button>
          </div>
        </div>

      <ag-grid-vue
        ref="agGridTable"
        :components="cellRendererComponents"
        class="ag-theme-material mb-3"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="giftVouchers"
        :gridOptions="gridOptions"
        :context="context"
        domLayout="autoHeight"
        rowSelection="single"
        :pagination="false"
        :suppressPaginationPanel="true"
        @first-data-rendered="onFirstDataRendered"
        @grid-ready="onGridReady"
      ></ag-grid-vue>
    </vx-card>
    
  </travio-center-container>
</template>

<script>

import Vue from "vue"
import {AgGridVue} from "ag-grid-vue";
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import CellRendererActiveCheckbox from './cell-renderers/CellRendererActiveCheckbox.vue'
import CellRendererActions from './cell-renderers/CellRendererActions.vue'
import { format } from 'date-fns'

export default {
  components: {
    AgGridVue,
    CellRendererActions,
    CellRendererActiveCheckbox
  },
  props: {
    applicationId: { required: true }
  },
  data () {
    return {
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      rowSelection: null,
      cellRendererComponents: {
        CellRendererGsheetLink: CellRendererActiveCheckbox
      },
      giftVouchers: []
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    }
  },
  async created () {

    if( !(this.activeUserInfo.applications && this.activeUserInfo.applications.find(x => x.id == this.applicationId)) ) {
      this.$router.push('/error-404')
    }
    this.$vs.loading()

    try {
      const response = await this.$http.get(`api/giftVouchers/apps/${this.applicationId}`)
      this.giftVouchers = response.data

    } catch (error) {
      this.$_notifyFailure(error)
    }

    this.$vs.loading.close();
  },
  beforeMount () {
    this.gridOptions = {};

    // Vue.extend for CellRenderer is not documented, see https://github.com/ag-grid/ag-grid/issues/3575 instead
    this.columnDefs =  [
      { hide: true, field: 'id' },
      { headerName: '', width: 80,  suppressSizeToFit: true, cellRendererFramework: Vue.extend(CellRendererActions) },
      { headerName: 'Name', field: 'name', suppressSizeToFit: true, sortable: true, width: 220 },
      { headerName: 'Customer Name', field: 'customerName', suppressSizeToFit: true, sortable: true, width: 240 },
      { headerName: 'Customer Email', field: 'customerEmail', suppressSizeToFit: true, sortable: true, width: 240 },
      { headerName: 'Amount', field: 'amountBalance', suppressSizeToFit: true, sortable: true, width: 120 },
      { headerName: 'Currency', field: 'currencyCode', suppressSizeToFit: true, sortable: false, width: 120 },
      { headerName: 'Active', width: 120,  suppressSizeToFit: true, cellRendererFramework: Vue.extend(CellRendererActiveCheckbox) },
      { 
        headerName: 'Created',
        field: 'createdDate',
        sortable: true,
        width: 190, 
        valueFormatter: (param) => param.value && format(new Date(param.value) , this.activeUserInfo.dateTimeFormat),
        suppressSizeToFit: false,
      }
    ]

    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      sortable: true,
      resizable: true,
      autoHeight: true,
      suppressMenu: true
    }

    this.context = { componentParent: this }
  },
  mounted () {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    // See https://www.ag-grid.com/javascript-grid-resizing/#size-columns-to-fit
    this.gridApi.sizeColumnsToFit();
  },
  methods: {
    onGridReady () {
      this.gridApi.sizeColumnsToFit && this.gridApi.sizeColumnsToFit()
    },
    onFirstDataRendered(params) {
      params.api.sizeColumnsToFit();
    },
    onAdd () {
      this.$router.push({ name: 'application-tools-gift-vouchers-add', 
        params: { 
          applicationId: this.applicationId,
        }
      })
    },
    onEdit (voucherId) {
      this.$router.push({ name: 'application-tools-gift-vouchers-edit', 
        params: { 
          applicationId: this.applicationId,
          giftVoucherId: voucherId
        }
      })
    },
  }
}
</script>

<style>

</style>